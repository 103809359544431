<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
        variant="danger"
        :show="elementData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t(ELEMENT_NAME + '.search.error.fetching_data')}}
      </h4>
    </b-alert>
    <template v-if="elementData">
      <b-row>
        <b-col
            cols="12"
            xl="12"
            lg="12"
            md="12"
        >
          <element-info-card
              :element-data="elementData"
              :element-name="ELEMENT_NAME" />
        </b-col>
      </b-row>
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import {
  BRow, BCol, BButton, BAlert, BLink,
} from 'bootstrap-vue'
import ElementStoreModule from '../storeModule'
import ElementInfoCard from './InfoCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BAlert,
    BLink,
    // Local Components
    ElementInfoCard,
  },
  data() {
    return {
      elementData: {},
      ELEMENT_APP_STORE_MODULE_NAME: '',
      ELEMENT_NAME: ''
    }
  },
  mounted() {
    this.ELEMENT_NAME = 'service-bundles'
    this.ELEMENT_APP_STORE_MODULE_NAME = 'app-sb-v-' + this.ELEMENT_NAME
    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.registerModule(this.ELEMENT_APP_STORE_MODULE_NAME, ElementStoreModule)

    this.fetchElement()
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    fetchElement() {
      this.newElement = false
      store.dispatch(this.ELEMENT_APP_STORE_MODULE_NAME + '/fetch_element', { id: router.currentRoute.params.id }).then(response => {
        this.elementData = response.data
        if (this.elementData.logo_id) {
          this.fetchLogo(this.elementData.logo_id)
        }
      }).catch(error => {
        if (error.response.status === 404) {
          this.elementData = undefined
        }
      })
    },
  }
}
</script>

<style>

</style>
