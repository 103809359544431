<template>
  <b-card>
    <b-card-header>
      <div class="text-primary h2">
        <feather-icon
          icon="StarIcon"
          size="25"
          class="mr-0 mr-sm-50"
          variant="primary"
        />
        <span class="font-weight-bold">{{ $t(elementName + '.name') }}</span>
      </div>
    </b-card-header>
    <b-card-body>
      <b-row>
        <!-- Right Col: Table -->
        <b-col
          cols="12"
          xl="12"
        >
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(elementName+'.object.code') }}</span>
              </th>
              <td class="pb-50">
                {{ elementData.code }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(elementName+'.object.name') }}</span>
              </th>
              <td class="pb-50">
                {{ elementData.bundle_name ? elementData.bundle_name.value : '' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(elementName+'.object.validity') }}</span>
              </th>
              <td class="pb-50">
                {{ elementData.validity ? elementData.validity : '' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(elementName+'.object.arl') }}</span>
              </th>
              <td class="pb-50">
                {{ elementData.arl ? elementData.arl.name : '' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(elementName+'.object.start_date') }}</span>
              </th>
              <td class="pb-50">
                {{ formatDate(elementData, 'start_date') }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(elementName+'.object.end_date') }}</span>
              </th>
              <td class="pb-50">
                {{ formatDate(elementData, 'end_date') }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t("services"+'.plural_name') }}</span>
              </th>
              <td class="pb-50">
                <div>
                  <table>
                    <thead>
                      <tr class="grid_table">
                        <th class="grid_table">
                          {{ $t("services"+'.object.group_name') }}
                        </th>
                        <th class="grid_table">
                          {{ $t("services"+'.object.services') }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="group in elementData.groups"
                        :key="group.id"
                        class="grid_table"
                      >
                        <td class="grid_table">
                          {{ group.name }}
                        </td>
                        <td class="grid_table">
                          <table>
                            <thead>
                              <tr>
                                <th>{{ $t("services"+'.object.intern_code') }}</th>
                                <th>{{ $t("services"+'.object.service_type') }}</th>
                                <th>{{ $t("services"+'.object.cups_name') }}</th>
                                <th>{{ $t("services"+'.object.cups') }}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="element in group.services"
                                :key="element.id"
                              >
                                <td>{{ element.service.intern_code }}</td>
                                <td>{{ element.service.service_type.value }}</td>
                                <td>{{ element.service.cups_name }}</td>
                                <td>{{ element.service.cups }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
      <b-row class="d-flex justify-content-end">
        <b-button
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="router().go(-1)"
        >
          {{ $t('actions.back') }}
        </b-button>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BButton, BAvatar, BRow, BCol, BBadge,
} from 'bootstrap-vue'

import { avatarText } from '@core/utils/filter'
import moment from 'moment'
import router from '@/router'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BBadge, BCardHeader, BCardBody,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    elementName: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      avatarText,
      resolveElementStatusVariant(status) {
        if (status === 0) return 'warning'
        return 'success'
      },
      resolveElementStatusMessage(status) {
        if (status == 0) return this.$t('user.statusValue.inactive')
        return this.$t('user.statusValue.active')
      },
      formatDate(element, field) {
        return moment(element[field], 'DD/MM/YYYY').format('DD/MM/YYYY')
      },
    }
  },
  methods: {
    router() {
      return router
    },
  },
}
</script>

<style>
.grid_table {
  border: 1px solid black;
  border-collapse: collapse;
}
</style>
